import React from 'react'
import "../styles/Footer.css"
function Footer() {
  return (
    <div className="footer">
        <div></div>
        <p>&copy; 2023 Amadeo Costaldi</p>
    </div>
  )
}

export default Footer